<template>
  <div>
    <div class="wrapper">
      <div class="all_navBar">
        <router-link v-for="(item, ind) in all_navBar"
                     :key="ind"
                     :to="item.path">
          <span :id="SelectedInd == ind ? 'Selected' : ''"
                :class="
              ind == 0
                ? 'first_child'
                : ind == all_navBar.length - 1
                ? 'last_child'
                : ''
            "
                @click="Selected(ind)">{{ item.name }}</span>
        </router-link>
      </div>
    </div>
    <div class="conent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      all_navBar: [
        {
          name: '申请登记表',
          path: '/index/equipment/table/tableOne',
        },
        {
          name: '变更登记表',
          path: '/index/equipment/table/tableTwo',
        },
        {
          name: '转移登记表',
          path: '/index/equipment/table/tableThree',
        },
        {
          name: '注销登记表',
          path: '/index/equipment/table/tableFour',
        },
        {
          name: '登记表审查',
          path: '/index/equipment/table/tableFive',
        },
        {
          name: '适航证查询',
          path: '/index/equipment/table/tableSix',
        },
      ],
      SelectedInd: 0,
    };
  },
  mounted() {
    //刷新页面定位到路由指定的nav
    let activePath = this.$route.path;
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });
  },
  methods: {
    Selected(ind) {
      this.SelectedInd = ind;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .all_navBar {
    display: flex;
    span {
      display: block;
      width: 148px;
      height: 34px;
      background-color: #fff;
      line-height: 34px;
      text-align: center;
      border-right: 1px solid #f0f0f0;
      font-size: 14px;
      color: #999;
    }
    .first_child {
      border-radius: 8px 0px 0px 8px;
    }
    .last_child {
      border-radius: 0px 8px 8px 0px;
    }
    #Selected {
      background-color: #409eff;
      color: #fff;
    }
  }
  .add {
    width: 82px;
    height: 35px;
    line-height: 35px;
    background: #4c98ff;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
}
</style>